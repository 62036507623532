/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Black.ttf") format("truetype"); /* Modern Browsers */
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Medium.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Sailec";

  src: url("/assets/fonts/Sailec-Light.woff2") format("woff2"); /* Modern Browsers */
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'Sailec', 'Roboto', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F5F7F6;
}
body * {
  box-sizing: border-box;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}



ul ul {
  list-style-type: none;
  padding-inline-start: 18px;
  padding-top: 5px;
  & > li {
    text-indent: -17px;
  }
  & > li:before {
    content: "\2014 ";
    margin-right: 5px;
  }
}

.reactour__helper {
  outline: none;
}

div.success-snack {
  background-color: #029094!important;
}

div.list-item {
  display: list-item;
  margin-left: 14px;
}

a {
  color: #1C434F;
  text-decoration: underline;
}

.no-overflow {
  overflow: hidden!important
}

.shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

iframe .ytp-expand-pause-overlay {
  display: none!important;
}
